import '../../style/style.css';
import {
  setTitleFilter,
  selecTitleFilter,
} from '../../redux/slices/filterSlice';
import { useDispatch, useSelector } from 'react-redux';

const Filter = () => {
  const dispatch = useDispatch();
  const titleFilter = useSelector(selecTitleFilter);
  const handleTitleFilterChange = (e) => {
    dispatch(setTitleFilter(e.target.value));
  };

  return (
    <div className="app-block filter">
      <div className="filter-group">
        <input
          value={titleFilter}
          type="text"
          placeholder="Фильтр по заголовку..."
          onChange={handleTitleFilterChange}
        />
      </div>
    </div>
  );
};

export default Filter;
