import { createSlice } from '@reduxjs/toolkit';

//Начальное состояние
const initialState = {
  title: '',
};

//Редюсер
const filterSlice = createSlice({
  name: 'filter', //название редюсера
  initialState: initialState, //начальное состояние редюсера
  reducers: {
    //свойство
    setTitleFilter: (state, action) => {
      return { ...state, title: action.payload };
    },
  },
});

// console.log(filterSlice.actions);
// console.log(filterSlice.actions.setTitleFilter('text Test'));

export const { setTitleFilter } = filterSlice.actions;

export const selecTitleFilter = (state) => state.filter.title;

export default filterSlice.reducer;
